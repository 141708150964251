import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Share from '../components/Share'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import moment from 'moment'
import Prism from 'prismjs'
import 'highlight.js/styles/vs2015.css'
import { FaQuoteLeft, FaQuoteRight } from 'react-icons/fa'
import { Disqus } from 'gatsby-plugin-disqus'

export default function template (props) {
  const blog = props.data.contentfulBlogPost
  const date = moment(blog.createdAt).format('MMMM, Do YYYY')
  const tags = blog.tags || []
  const options = {
    renderNode: {
      [BLOCKS.QUOTE]: node => {
        return (
          <blockquote className='blog-quote'>
            <FaQuoteLeft style={{ alignSelf: 'flex-start', color: 'gray' }} />
            <p style={{ alignSelf: 'center' }}>{node.content[0].content[0].value}</p>
            <FaQuoteRight style={{ alignSelf: 'flex-end', color: 'gray' }} />
          </blockquote>)
      },
      [BLOCKS.EMBEDDED_ENTRY]: node => {
        const type = node.data.target.sys.contentType.sys.id

        const { title, language, code, codeSnip } = node.data.target.fields

        switch (type) {
          case 'codeSnippet':
            return (
              <section className='blog-code-snippet'>
                <h4>{title['en-US']}</h4>
                <pre className={`embedded-entry language-jsx language-javascript ${type} ${language['en-US'].toLowerCase()}`}>
                  <code>
                    {codeSnip['en-US']}
                  </code>
                </pre>
              </section>
            )

          default:
            return <pre className='embedded-entry'><code>const Hello = new Gatsby();</code></pre>
        }
      },
      [BLOCKS.EMBEDDED_ASSET]: node => {
        const { description, title, file } = node.data.target.fields
        return <div className='embedded-asset'><img alt={title['en-US']} src={file['en-US'].url} /><span className='embedded-asset-description'>{description['en-US']}</span></div>
      }
    }
  }

  const Content = documentToReactComponents(blog.body.json, options)

  const source = blog.headerImage.image.fluid.src.length > 0 ? blog.headerImage.image.fluid.src : ''

  const HeaderImage = blog.headerImage ? <img className='header-image' src={source} alt={blog.title} /> : ''

  const url = `https://www.berato.tech/${props.pageContext.slug}`

  const disqusConfig = {
    url,
    identifier: props.pageContext.slug,
    title: blog.title
  }

  if (typeof window !== 'undefined') {
    window.setTimeout(async () => {
      await Prism.highlightAll()
    }, 1000)
  }

  return (
    <Layout>
      <Seo title={blog.title} url={url} image={source} keywords={[...tags]} description={blog.summary.summary} />

      <article className='container blog_post'>
        <h1>{blog.title}</h1>
        <h2 className='subtitle'>{blog.subtitle}</h2>
        <div className='date'>{date}</div>
        <h3 className={`subject ${blog.subject.toLowerCase()}`}>{blog.subject}</h3>

        {HeaderImage}
        <Share title={blog.title} url={url} description={blog.summary.summary} media={source} hashtags={tags} />
        <section className='row blog-content'>
          {Content}
          <p className='keywords' style={{ color: 'grey' }}>
            Keywords:<br />
            {tags.map((keyword, idx) => {
              const brTag = (idx + 1) % 4 === 0 ? <br /> : ''
              return (
                <React.Fragment key={idx}>
                  <span style={{ border: '2px grey solid', borderRadius: '5px', padding: '5px', margin: '10px', marginBottom: '7px' }}>
                    #{keyword.toLowerCase().replace(' ', '-')}
                  </span>
                  {brTag}
                </React.Fragment>
              )
            })}
          </p>
        </section>
        <Disqus config={disqusConfig} style={{ width: '100%' }} />
      </article>
    </Layout>

  )
}

export const blogQuery = graphql`
query blogQuery($slug: String!) {
     contentfulBlogPost(slug: {eq: $slug}) {
    title
    subtitle
        headerImage {
      image {
        fluid {
          src
        }
      }
    }
    createdAt
    body {
      json
      childContentfulRichText {
        html
      }
      body
    }
    slug
    tags
    subject
            summary {
          summary
        }
  }
}
`
