import React from 'react'
import { TwitterShareButton, FacebookShareButton, PinterestShareButton, LinkedinShareButton, FacebookIcon, PinterestIcon, LinkedinIcon, TwitterIcon } from 'react-share'

const Share = props => {
  console.log(props)
  return (
    <div className='share-button' style={{ display: 'flex', position: 'fixed', flexDirection: 'column', top: '40%', left: 0 }}>
      <TwitterShareButton url={props.url} via='optymynd' title={props.title} hashtags={props.hashtags} children={<TwitterIcon size={32} />} />
      <FacebookShareButton url={props.url} title={props.title} quote={props.description} children={<FacebookIcon size={32} />} />
      <PinterestShareButton url={props.url} title={props.title} media={props.media} description={props.description} children={<PinterestIcon size={32} />} />
      <LinkedinShareButton url={props.url} title={props.title} summary={props.description} source={props.url} children={<LinkedinIcon size={32} />} />
    </div>
  )
}

export default Share
